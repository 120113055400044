.pf-c-select .pf-c-select__toggle:before {
  border-top: var(--pf-c-select__toggle--before--BorderTopWidth) solid
    var(--pf-c-select__toggle--before--BorderTopColor);
  border-right: var(--pf-c-select__toggle--before--BorderRightWidth) solid
    var(--pf-c-select__toggle--before--BorderRightColor);
  border-bottom: var(--pf-c-select__toggle--before--BorderBottomWidth) solid
    var(--pf-c-select__toggle--before--BorderBottomColor);
  border-left: var(--pf-c-select__toggle--before--BorderLeftWidth) solid
    var(--pf-c-select__toggle--before--BorderLeftColor);
}
/* https://github.com/patternfly/patternfly-react/issues/5650 */
